const theme = {
  colors: {
    primaryText: '#2e2d41',
    secondaryText: '#FFFFFF',
    primary: '#F16764',
    // primary: 'blue',
    primaryDark: '#9ebc40',
    secondray: '#F16764',
    linkHoverColor: '#d3d2d2',
    error: '#ff0000',
    primaryBgColor: '#f9f7f2',
    disabled: '#b5b5b5',
    linkColor: '#15c',
    summaryBg: '#f9f7f2',
  },
  boxShadow: {
    primary: '0px 0px 8px 0px rgba(0, 0, 0, 0.3)',
    secondary: '0px 0px 5px 5px rgba(46, 45, 65, 0.2)',
    errorFocus: `0px 0 6px 0 rgba(243, 0, 74, 1)`,
    secondaryGreen: '0px 0px 7px 0px rgba(187, 206, 63, 1)',
  },
  shadow: {
    boxShadow: `0px 0 6px 0 rgba(0, 0, 0, 0.3)`,
  },
  fonts: {
    Playfair: 'Playfair Display, serif',
    Raleway: 'Raleway, sans-serif',
    Roboto: 'Roboto, sans-serif',
    Lobster: 'Lobster, cursive',
    Arial: 'Arial, Helvetica, sans-serif',
    Lato: 'Lato, sans-serif',
    Caveat: 'Caveat, cursive',
  },
  breakpoints: {
    s: '576px',
    l: '992px',
  },
  // font-weight
  fw: {
    thin: 100,
    light: 300,
    regular: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
    extraBold: 800,
    maxBold: 900,
  },
  // font-size:
  fs: {
    xs: '1rem',
    base: '1.2rem',
    baseXL: '1.3rem',
    sm: '1.4rem',
    md: '1.6rem',
    lg: '1.8rem',
    h1: '5.2rem',
    h2: '3.2rem',
    h3: '2.8rem',
    h4: '2.6rem',
    h5: '2.4rem',
    h6: '2.2rem',
    h7: '2rem',
    startPopupDeskHead: '119px',
    startPopupDeskSubHead: '50px',
    startPopupMobBigHead: '62px',
    startPopupMobHead: '52px',
    startPopupMobSubHead: '16px',
  },
  gradient: {
    green: 'linear-gradient(to right,rgb(187,206,63), rgb(146,186,67)100%)',
    dark: 'linear-gradient(to right, #2e2d41, #110c23)',
    violet: 'linear-gradient(to right, #bb428b 0%, #8f3b81 100%)',
    orange: 'linear-gradient(to right, #F16764, #F16764)',
  },
  loader: {
    bgColor: 'green',
    bgPadding: `40px`,
    spinCircleBorderColor: `blue`,
    spinCircleBorderRadius: `50%`,
    spinBorderColor: `#F16764`,
    spinWidth: `120px`,
    spinHeight: '120px',
    spinBorderWidth: `6px`,
    border: `6px`,
  },
  borderRadius: {
    primary: `12px 12px 12px 12px`,
  },
};

export default theme;
