import React from 'react';
import styled, { keyframes } from 'styled-components/macro';
import RobotLogo from 'assets/img/Quiz/logo-1.png';

const LoaderBellsWrapper = styled.div`
  width: 100%;
  height: ${({ customHeight }) => customHeight || `100vh`};
  background: transparent;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const spinAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const CircleSpin = styled.div`
  display: inline-block;
  margin: auto;
  width: ${({ theme }) => theme.loader.spinWidth};
  height: ${({ theme }) => theme.loader.spinHeight};
  border: ${({ theme }) => `${theme.loader.border} solid ${theme.colors.disabled}`};
  border-radius: 50%;
`;
const Circle = styled.div`
  position: relative;
  width: ${({ theme }) => theme.loader.spinWidth};
  height: ${({ theme }) => theme.loader.spinHeight};
  margin: auto;
  border-radius: ${({ theme }) => theme.loader.spincircleborderradius};
  background: transparent;
  &:after {
    content: ' ';
    width: ${({ theme }) => theme.loader.spinWidth};
    height: ${({ theme }) => theme.loader.spinHeight};
    display: block;
    border-radius: 50%;
    border: ${({ theme }) =>
      `${theme.loader.spinBorderWidth} solid ${theme.loader.spinBorderColor}`};
    position: relative;
    left: ${({ theme }) => `-${theme.loader.spinBorderWidth}`};
    top: ${({ theme }) => `-${theme.loader.spinBorderWidth}`};
    border-color: ${({ theme }) => `${theme.loader.spinBorderColor} transparent transparent`};
    animation: 0.8s ease-in-out infinite ${spinAnimation};
    background: transparent;
  }
`;

const Logo = styled.img`
  width: calc(${({ theme }) => theme.loader.spinWidth} - 40px);
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Loader = ({ noBg, customHeight }) => {
  return (
    <LoaderBellsWrapper noBg={noBg} customHeight={customHeight}>
      <CircleSpin>
        <Logo src={RobotLogo} alt="" />
        <Circle />
      </CircleSpin>
    </LoaderBellsWrapper>
  );
};

export default Loader;
